<template>
    <setting-layout :title="$t('pages.user.setting.blacklist.title')">
        <v-container class=" pa-5 pt-2">

                <v-row align="center" class="mt-4" v-for="(item, index) in usersInBlacklist" :key="index">
                    <v-col cols="2">
                        <router-link :to="{name: 'otherUser.profile.viewSlug', params: { slug: item.slug, from: $route.name }}">
                            <profile-image
                                :src="item.avatar_url"
                                :username="item.name"
                            />
                        </router-link>
                    </v-col>
                    <v-col cols="6">
                        <router-link :to="{name: 'otherUser.profile.viewSlug', params: { slug: item.slug, from: $route.name }}">{{ item.name }}</router-link>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="3">
                        <span class="unblock" @click="unblock(item.id)">{{ $t('dialogs.unblock') }}</span>
                    </v-col>
                </v-row>

        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import ProfileImage from "@/components/app/notifications/ProfileImage";

import {mapActions, mapState} from "vuex";
export default {
    name: "BlockedUser",
    data: () => ({
        usersInBlacklist: [

        ]

    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        })
    },
    methods: {
        unblock(id){
            this.deleteBlockedUsers(id).then(()=>{
                this.setBlockedUsers().then(res => this.usersInBlacklist = res)
            })
        },
        ...mapActions(['setBlockedUsers', 'deleteBlockedUsers'])
    },
    mounted() {
        this.setBlockedUsers().then(res => this.usersInBlacklist = res)

        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Blocked users');
    },
    components: {
        SettingLayout,
        ProfileImage,
    },
}
</script>

<style scoped>
.profile-image{
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}
.unblock{
    color: #FE4811;
    font-size: 0.9em;
}
</style>
